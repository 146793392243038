import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import axios from "axios";

const SendWhatsAppCampaign = () => {
  // Common state
  const [numbercounter, setNumberCounter] = useState("");
  const [mobile_no, setMobile_No] = useState("");
  const [count, setCount] = useState(0);
  const [contactGroupData, setContactGroupData] = useState([]);
  const [isloading, setLoading] = useState(false);
  const [responseNumber, setResponseNumber] = useState("");

  // WhatsApp state
  const [templates, setTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState("");
  const [selectedTemplateType, setSelectedTemplateType] = useState("");
  const [imageFile, setImageFile] = useState(null);
  const token = localStorage.getItem("token"); // Retrieve token from local storage

  const BASE_URL = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    const theme = localStorage.getItem("theme");
    document.body.className = theme;
    if (numbercounter.length == 0) {
      setNumberCounter(0);
    }
  }, [count]);

  const getWhatsAppTemplates = () => {
    const token = localStorage.getItem("token"); // Retrieve token from local storage
    axios
      .post(
        `${BASE_URL}admin/getAllTemplates`,
        { statusFilter: "approved" },
        {
          headers: {
            "x-access-token": `${token}`,
            version: "1.0.0",
            "Content-Type": "Application/json",
          },
        }
      )
      .then((response) => {
        setTemplates(response.data.data.data || []);
      })
      .catch((error) => {
        console.log("Error fetching templates:", error);
      });
  };

  const GetAllContactGroup = () => {
    const token = localStorage.getItem("token"); // Retrieve token from local storage
    axios
      .post(
        `${BASE_URL}admin/getAllContactGroup`,
        { page: 1 },
        {
          headers: {
            "x-access-token": `${token}`,
            version: "1.0.0",
            "Content-Type": "Application/json",
          },
        }
      )
      .then((response) => {
        if (response.data.success !== false) {
          setContactGroupData(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getWhatsAppTemplates();
    GetAllContactGroup();
    const theme = localStorage.getItem("theme");
    document.body.className = theme;
  }, []);

  const handleImageUpload = (e) => {
    setImageFile(e.target.files[0]);
  };
  const RowCount = (text) => {
    var lines = text.split(/\r|\r\n|\n/);
    setCount(lines.length);
    setNumberCounter(count);
    setMobile_No(text);
  };

  const submitWhatsAppTemplate = () => {
    setLoading(true);
    if (!selectedTemplate) {
      toast.error("Template is mandatory");
      setLoading(false);
      return;
    }

    // if (selectedTemplateType === "image" && !imageFile) {
    //   toast.error("Image is required for image templates");
    //   setLoading(false);
    //   return;
    // }

    // Create FormData only if we have an image file
    if (selectedTemplateType === "image" && imageFile) {
      const formData = new FormData();
      formData.append("contact_no", mobile_no);
      formData.append("msg_count", count);
      formData.append("template_id", selectedTemplate);
      formData.append("media_type", "WHATSAPP");
      // formData.append("image", imageFile);

      axios
        .post(`${BASE_URL}admin/addSendMessage`, formData, {
          headers: {
            "x-access-token": `${token}`,
            "Content-Type": "multipart/form-data",
            version: "1.0.0",
          },
        })
        .then((response) => {
          if (response.data.success == false) {
            toast.error(response.data.message);
          } else {
            toast.success(response.data.message);
            setNumberCounter("");
            setMobile_No("");
            setImageFile(null);
          }
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } else {
      // Regular JSON request for non-image templates
      axios
        .post(
          `${BASE_URL}admin/addSendMessage`,
          {
            contact_no: mobile_no,
            msg_count: count,
            template_id: selectedTemplate,
            media_type: "WHATSAPP",
          },
          {
            headers: {
              "x-access-token": `${token}`,
              "Content-Type": "Application/json",
              version: "1.0.0",
            },
          }
        )
        .then((response) => {
          if (response.data.success == false) {
            toast.error(response.data.message);
          } else {
            toast.success(response.data.message);
            setNumberCounter("");
            setMobile_No("");
          }
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    }
  };

  // Group selection handler
  const GroupRowCount = (text) => {
    var lines = text.split(/\r|\r\n|\n/);

    setCount(lines.length);

    setNumberCounter(count);
    setMobile_No(text);
  };
  return (
    <div style={{ padding: "30px" }}>
      <div className="card">
        <div className="card-header p-3">
          <span>
            <i className="fa fa-pen"></i>&nbsp;&nbsp;
            <h6 className="d-inline">Send Whatsapp message</h6>
          </span>
        </div>
        <div className="card-body">
          {/* Mobile Number Input */}
          <div className="row m-3">
            <div className="col-3 col-xs-3 col-sm-3 col-md-3 col-lg-2">
              <label className="m-2">Enter Mobile Number:</label>
            </div>
            <div className="col-9 col-xs-9 col-sm-9 col-md-9 col-lg-4">
              <textarea
                rows={4}
                cols={4}
                className="form-control inputType"
                placeholder="Enter 10 digits mobile number here"
                onChange={(e) => RowCount(e.target.value)}
                name="mobile_no"
                value={mobile_no}
              ></textarea>
            </div>

            <div className="col col-6">
              <div className="row">
                <label
                  className="col-3 col-xs-3 col-sm-3 col-md-3 col-lg-2 mt-4"
                  style={{ width: "250px" }}
                >
                  Select Template:
                </label>
                <select
                  style={{ maxWidth: "400px" }}
                  className="form-select inputType mt-2"
                  onChange={(e) => {
                    const selectedId = e.target.value;
                    setSelectedTemplate(selectedId);

                    // Find the template and update the template type
                    const template = templates.find(
                      (t) => t._id === selectedId
                    );
                    if (template) {
                      setSelectedTemplateType(template.templateType);
                    }
                  }}
                >
                  <option disabled selected>
                    Select WhatsApp template
                  </option>
                  {templates.map((template) => (
                    <option key={template._id} value={template._id}>
                      {template.templateName}
                    </option>
                  ))}
                </select>
              </div>

              {/* Only show image upload field if template type is 'image' */}
              {/* {selectedTemplateType === "image" && (
                <div className="row">
                  <label
                    className="col-3 col-xs-3 col-sm-3 col-md-3 col-lg-2 mt-4"
                    style={{ width: "250px" }}
                  >
                    Upload Image:
                  </label>
                  <div className="col-9 col-xs-9 col-sm-9 col-md-9 col-lg-4 mt-3">
                    <input
                      type="file"
                      className="form-control inputType"
                      onChange={handleImageUpload}
                      accept="image/*"
                    />
                  </div>
                </div>
              )} */}
            </div>
          </div>

          {/* Counter display */}
          <div className="row m-3">
            <div className="col-3 col-xs-3 col-sm-3 col-md-3 col-lg-2">
              <label className="m-2">Count:</label>
            </div>
            <div className="col-9 col-xs-9 col-sm-9 col-md-9 col-lg-4">
              <input
                type="text"
                className="form-control inputType"
                name="numbercounter"
                value={count}
                disabled
              />
            </div>
          </div>

          {/* Group import */}
          <div className="row m-3">
            <div className="col-3 col-xs-3 col-sm-3 col-md-3 col-lg-2">
              <label className="m-2">Group Import</label>
            </div>
            <div className="col-9 col-xs-9 col-sm-9 col-md-9 col-lg-4">
              <select
                className="form-control inputType"
                onChange={(e) => GroupRowCount(e.target.value)}
              >
                <option className="">--select--</option>
                {contactGroupData &&
                  contactGroupData.map((item, index) => (
                    <option key={index} value={item.contact_no}>
                      {item.group_name}
                    </option>
                  ))}
              </select>
            </div>
          </div>

          {/* Submit button */}
          <div className="d-flex justify-content-center align-items-center m-5">
            {isloading ? (
              <button type="button" className="btn btn-primary inputType">
                <i
                  className="fa fa-spinner fa-spin"
                  style={{ fontSize: "25px" }}
                ></i>
              </button>
            ) : (
              <button
                type="button"
                className="btn btn-primary"
                onClick={submitWhatsAppTemplate}
              >
                Submit
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SendWhatsAppCampaign;
