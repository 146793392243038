import axios from "axios";

const setupAxiosInterceptors = () => {

  axios.interceptors.response.use(
    (response) => {
      if (response && response.data && response.data.error_code === 463) {
        localStorage.clear();


        window.location.href = "/login";
        console.error("Session expired. You have been logged out.");
      }
      else {
        console.log(response);

      }
      return response;
    },
    (error) => {
      if (error.response && error.response.error_code === 463) {
        localStorage.clear();


        window.location.href = "/login";
        console.error("Session expired. You have been logged out.");
      }
      else {
        console.log(error);
      }

      return Promise.reject(error);
    }
  );
};


// axios.interceptors.request.use(
//   (request) => {
//     request.setHeader({
//       'ngrok-skip-browser-warning': 'true',
//     }
//     );

//     return request;
//   }
// )
export default setupAxiosInterceptors;
