import React from "react";
import SendWhatsAppCampaign from "./SendWhatsAppCampaign";
import Sidebar from "../../Components/Sidebar";
import Header from "../../Components/Header";
import CustomTabsV2 from "../../Components/CustomTabs";
import SendAudioCampaign from "./SendAudioCampaign";

const SendCampaign = () => {
  // Tab configuration
  const tabs = [
    {
      label: "Send Voice Message",
      to: "voice",
      content: <SendAudioCampaign />,
    },
    {
      label: "Send WhatsApp Message",
      to: "whatsapp",
      content: <SendWhatsAppCampaign />,
    },
  ];

  return (
    <>
      <div id="main-wrapper">
        <Header />
        <Sidebar />
        <div className="content-body" style={{ height: "100vh" }}>
          <div className="page-titles">
            <ol className="breadcrumb">
              <li>
                <h5 className="bc-title">Get Media Approval</h5>
              </li>
              <li className="breadcrumb-item">
                <a href="javascript:void(0)">Home</a>
              </li>
              <li className="breadcrumb-item active">
                <a href="javascript:void(0)">Get Media Approval</a>
              </li>
            </ol>
          </div>

          <div className="col-12 bst-seller pt-3" style={{ padding: "30px" }}>
            <div className="card">
              <CustomTabsV2 tabs={tabs} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SendCampaign;
