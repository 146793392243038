import { useRef, useState } from "react";
import Header from "../../Components/Header";
import Sidebar from "../../Components/Sidebar";
import { toast } from "react-toastify";
import axios from "axios";

function GetAudioAprroval() {
  const [audio, setAudio] = useState(null);
  const [fileName, setFileName] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  var audioInputRef = useRef(0);

  const handleFileNameChange = (e) => {
    const value = e.target.value;

    if (value.length > 30) {
      setErrorMessage("File name cannot exceed 30 characters.");
    } else {
      setErrorMessage(""); // Clear the error message if valid
      setFileName(value);
    }
  };

  const OnSubmit = () => {
    setLoading(true);
    if (audio == null || audio.length === 0) {
      toast.error("audio is mandatory");
      setLoading(false);
      return;
    }

    // Validation: Check file type for .mp3
    const file = audio[0];
    const allowedExtensions1 = /(\.mp3)$/i;
    if (
      !(
        allowedExtensions1.exec(file.name)
      )
    ) {
      toast.error("Only MP3 files are allowed");
      setLoading(false);
      return;
    }
    let formData = new FormData();
    formData.append("audio", audio[0]);
    formData.append("fileName", fileName);
    const token = localStorage.getItem("token");
    axios
      .post(`${BASE_URL}admin/getAudioApproval`, formData, {
        headers: {
          "x-access-token": `${token}`,
          "Content-Type": "multipart/form-data",
          version: "1.0.0",
        },
      })
      .then((response) => {
        setLoading(false);
        if (response.data.success == false) {
          toast.error(response.data.message);
        } else {
          toast.success(response.data.message);
          setFileName("");
          audioInputRef.current.value = null;
          setAudio(null);
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error("Upload audio file failed.. Please contact the admin");
        setLoading(false);
      });
  }; //end of OnSubmit

  return (

    <div style={{ padding: "30px" }}>
      <div className="card">
        <div className="card-header p-3">
          <span>
            <i className="fa fa-pen"></i>&nbsp;&nbsp;
            <h6 className="d-inline">Get Audio approval</h6>
          </span>
        </div>
        <div className="card-body">
          <div className="row m-3">
            <div className="col-3 col-xs-3 col-sm-3 col-md-3 col-lg-2">
              <label className="m-2">Audio File name :-</label>
            </div>
            <div className="col-9 col-xs-9 col-sm-9 col-md-9 col-lg-4  mt-2">
              <input
                type="text"
                placeholder="Enter a name (defaults to file name)"
                className="form-control inputType"
                name="fileName"
                onChange={handleFileNameChange}
                value={fileName}
              />
              {errorMessage && (
                <p style={{ color: "red" }}>{errorMessage}</p>
              )}
            </div>
          </div>
          <div className="row m-3">
            <div className="col-3 col-xs-3 col-sm-3 col-md-3 col-lg-2">
              <label className="m-2">Upload Audio:-</label>
            </div>
            <div className="col-5 col-xs-9 col-sm-9 col-md-9 col-lg-4  mt-2">
              <input
                ref={audioInputRef}
                id="audio"
                type="file"
                className="form-control inputType"
                name="audio"
                accept=".mp3"
                onChange={(e) => setAudio(e.target.files)}
                multiple={false}
              />
              <div style={{ fontSize: '0.8em', color: 'red', marginTop: '5px' }}>
                Only MP3 audios are accepted.
              </div>
            </div>

          </div>
          <div className="row m-3">
            <div className="col-2">
              <input
                type="button"
                className="btn btn-primary m-2"
                onClick={OnSubmit}
                value="upload"
                disabled={loading}
              ></input>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
} //end of function GetAudioAprroval

export default GetAudioAprroval;
