import React from "react";
import { Box, Tabs, Tab, Divider } from "@mui/material";
import Header from "../../Components/Header";
import Sidebar from "../../Components/Sidebar";
import CustomTabsV2 from "../../Components/CustomTabs";
import GetAudioApproval from "./GetAudioApproval";
import WhatsAppMessagePreview from "../../Components/WhatsAppMessagePreview";

const GetMediaApproval = () => {
  const tabs = [
    { label: "Audio Approval", to: "audio", content: <GetAudioApproval /> },
    {
      label: "Whatsapp Template Approval",
      to: "template",
      content: <WhatsAppMessagePreview />,
    },
  ];

  return (
    <>
      <div id="main-wrapper">
        <Header />
        <Sidebar />
        <div className="content-body" style={{ height: "100vh" }}>
          <div className="page-titles">
            <ol className="breadcrumb">
              <li>
                <h5 className="bc-title">Get Media Approval</h5>
              </li>
              <li className="breadcrumb-item">
                <a href="javascript:void(0)">Home</a>
              </li>
              <li className="breadcrumb-item active">
                <a href="javascript:void(0)">Get Media Approval</a>
              </li>
            </ol>
          </div>

          <div className="col-12 bst-seller pt-3" style={{ padding: "30px" }}>
            <div className="card">
              <CustomTabsV2 tabs={tabs} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GetMediaApproval;
