import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Select,
  MenuItem,
  TextField,
  RadioGroup,
  Radio,
  FormControlLabel,
  Button,
  IconButton,
  Tooltip,
  InputAdornment,
  FormControl,
  InputLabel,
} from "@mui/material";
import { PhotoCamera, PictureAsPdf, VideoLibrary } from "@mui/icons-material";

import {
  Image as ImageIcon,
  MessageSquare,
  Phone,
  Link as LinkIcon,
  X,
  Plus,
} from "lucide-react";
import axios from "axios";
import { toast } from "react-toastify";

export default function WhatsAppMessagePreview({ id, isViewMode }) {
  const [templateType, setTemplateType] = useState("");
  const [templateFormat, setTemplateFormat] = useState("");
  const [templateFooter, setTemplateFooter] = useState("");
  const [templateName, setTemplateName] = useState("");
  const [interactiveAction, setInteractiveAction] = useState("none");
  const [quickReplies, setQuickReplies] = useState([]);
  const [newQuickReply, setNewQuickReply] = useState("");
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const token = localStorage.getItem("token");
  const [imageFile, setImageFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null); // For image preview
  const [fileType, setFileType] = useState("");
  const [callToActions, setCallToActions] = useState([
    {
      type: "",
      buttonTitle: "",
      buttonValue: "",
      countryCode: "+91", // New state for country code
    },
  ]);

  const downloadFile = (fileUrl) => {
    if (!fileUrl) {
      toast.error("No file available to download.");
      return;
    }

    const fileName = fileUrl.split("/").pop();
    console.log(fileUrl);

    saveAs(BASE_URL + fileUrl, fileName);
  };

  const getFileType = (fileUrl) => {
    if (!fileUrl) return null;

    const extension = fileUrl.split(".").pop().toLowerCase();

    if (["png", "jpg", "jpeg"].includes(extension)) return "image";
    if (["mp4", "mov", "avi"].includes(extension)) return "video";
    if (["pdf"].includes(extension)) return "pdf";

    return "unknown"; // Default case if format is unsupported
  };

  const getUserTemplateById = () => {
    axios
      .get(`${BASE_URL}admin/getUserTemplate/` + id, {
        headers: {
          "Content-Type": "Application/json",
          version: "1.0.0",
          "x-access-token": `${token}`,
        },
      })
      .then((response) => {
        if (!response.data.success) {
          toast.error("Unable to load templates..please contact admin");
        } else {
          setTemplateData(response);
        }
      })
      .catch((error) => {
        toast.error("Unable to load templates..please contact admin");
      });
  };

  const setTemplateData = (response) => {
    const template = response.data.data || {}; // Ensure there's a valid object
    console.log(response);

    setTemplateName(template.templateName || "");
    setTemplateType(template.templateType || "");
    setTemplateFormat(template.templateFormat || "");
    setTemplateFooter(template.templateFooter || "");
    setImageFile(template.fileUrl || "");
    setPreviewUrl(template.fileUrl ? BASE_URL + template.fileUrl : "");

    setFileType(getFileType(template.fileUrl));

    setInteractiveAction(
      template.callToActions?.length > 0 && template.quickReplies?.length > 0
        ? "all"
        : template.callToActions?.length > 0
        ? "cta"
        : template.quickReplies?.length > 0
        ? "quick"
        : "none"
    );

    setQuickReplies(template.quickReplies || []);
    setCallToActions(
      template.callToActions.length > 0
        ? template.callToActions
        : [{ type: "", buttonTitle: "", buttonValue: "", countryCode: "+91" }]
    );
  };

  useEffect(() => {
    console.log("check");

    if (isViewMode === true && id != null) {
      getUserTemplateById(id);
    }
  }, []);
  const maxChars = 1024;
  const footerMaxChars = 60;
  const buttonTitleMaxChars = 25;
  const quickReplyMaxChars = 25;
  const maxQuickReplies = 5;

  const handleCallToActionChange = (index, field, value) => {
    const updatedActions = [...callToActions];
    updatedActions[index] = { ...updatedActions[index], [field]: value };
    setCallToActions(updatedActions);
    console.log(updatedActions);
  };

  const handleQuickReplyAdd = () => {
    if (newQuickReply && quickReplies.length < maxQuickReplies) {
      setQuickReplies([...quickReplies, newQuickReply]);
      setNewQuickReply("");
    }
  };

  const removeQuickReply = (index) => {
    setQuickReplies(quickReplies.filter((_, i) => i !== index));
  };

  const phoneStyles = {
    container: {
      position: "relative",
      margin: "0 auto",
      border: "10px solid #1A1A1A",
      borderRadius: "55px",
      height: "700px",
      width: "340px",
      background: "#000",
      boxShadow:
        "0 0 20px rgba(0, 0, 0, 0.2), inset 0 0 10px rgba(255, 255, 255, 0.1)",
      overflow: "hidden",
    },

    dynamicIsland: {
      position: "absolute",
      top: "12px",
      left: "50%",
      transform: "translateX(-50%)",
      width: "120px",
      height: "25px",
      background: "#000",
      borderRadius: "20px",
      zIndex: 10,
    },
    screen: {
      height: "100%",
      background: "#fff",
      position: "relative",
      overflow: "hidden",
      borderRadius: "40px",
    },
    statusBar: {
      position: "absolute",
      top: "5px",
      left: 0,
      right: 0,
      padding: "15px",
      display: "flex",
      justifyContent: "space-between",
      color: "#000",
      fontSize: "14px",
      zIndex: 5,
    },
    whatsappHeader: {
      background: "#075E54",
      color: "#fff",
      padding: "60px 16px 16px",
      display: "flex",
      alignItems: "center",
      gap: "12px",
    },
    chatBackground: {
      background: "#E5DDD5",
      height: "calc(100% - 114px)",
      padding: "16px",
      overflowY: "auto",
    },
    messageBubble: {
      maxWidth: "85%",
      marginLeft: "auto",
      background: "#fff",
      borderRadius: "12px",
      padding: "12px",
      boxShadow: "0 1px 2px rgba(0,0,0,0.1)",
      marginBottom: "4px",
    },
    imagePreview: {
      width: "100%",
      aspectRatio: "16/9",
      background: "#f0f0f0",
      borderRadius: "8px",
      marginBottom: "8px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    actionButton: {
      width: "100%",
      background: "#fff",
      border: "1px solid #128C7E",
      borderRadius: "8px",
      color: "#128C7E",
      fontSize: "14px",
      paddingTop: "4px",
      paddingBottom: "4px",
      textAlign: "center",
      cursor: "pointer",
      margin: "1px",
    },
    actionButtonWrapper: {
      width: "85%",
      marginLeft: "auto",
      marginTop: "4px",
      cursor: "pointer",
    },
    quickReply: {
      width: "100%",
      background: "#d9d4d9",
      border: "1px solid #128C7E",
      borderRadius: "8px",
      color: "#128C7E",
      fontSize: "14px",
      paddingTop: "4px",
      paddingBottom: "4px",
      textAlign: "center",
      cursor: "pointer",
      margin: "1px",
    },
  };

  const handleInteractiveChange = (event) => {
    const value = event.target.value;
    setInteractiveAction(value);

    if (value === "none") {
      setCallToActions([]);
      setQuickReplies([]);
    }
  };

  const handleSubmit = async () => {
    const token = localStorage.getItem("token");

    try {
      const formData = new FormData();
      formData.append("templateName", templateName);
      formData.append("templateType", templateType);
      formData.append("templateFormat", templateFormat);
      formData.append("templateFooter", templateFooter);

      // Append callToActions correctly as an array (not a string)
      if (interactiveAction === "cta" || interactiveAction === "all") {
        callToActions
          .filter((cta) => cta.buttonTitle) // Ensure only valid buttons are added
          .forEach((cta, index) => {
            formData.append(`callToActions[${index}][type]`, cta.type);
            formData.append(
              `callToActions[${index}][buttonTitle]`,
              cta.buttonTitle
            );
            formData.append(
              `callToActions[${index}][buttonValue]`,
              cta.countryCode + cta.buttonValue
            );
            formData.append(
              `callToActions[${index}][countryCode]`,
              cta.countryCode // Append the country code
            );
          });
      }

      // Append quickReplies as an array
      if (interactiveAction === "quick" || interactiveAction === "all") {
        quickReplies.forEach((reply, index) => {
          formData.append(`quickReplies[${index}]`, reply);
        });
      }

      // Append image only if the templateType is "image" and an image is selected
      if (templateType === "image" && imageFile) {
        formData.append("file", imageFile);
      }

      console.log("Sending FormData:", [...formData.entries()]); // Debugging purpose

      const response = await axios.post(
        BASE_URL + "admin/whatsapp-template",
        formData,
        {
          headers: {
            "x-access-token": `${token}`,
            version: "1.0.0",
            "Content-Type": "multipart/form-data", // Axios sets this automatically
          },
        }
      );

      console.log("Template created successfully:", response.data);

      // Reset form fields
      setTemplateName("");
      setTemplateType("");
      setTemplateFormat("");
      setTemplateFooter("");
      setInteractiveAction("none");
      setQuickReplies([]);
      setCallToActions([]);
      setImageFile(null);

      alert("Template created successfully!");
    } catch (error) {
      console.error("Error creating template:", error);
      alert(
        `Error: ${error.response?.data?.message || "Failed to create template"}`
      );
    }
  };

  const handleAddCallToAction = () => {
    if (callToActions.length < 2) {
      setCallToActions([
        ...callToActions,
        {
          type: "",
          buttonTitle: "",
          buttonValue: "",
          countryCode: "+91", // New state for country code
        },
      ]);
    }
  };

  const handleRemoveCallToAction = (index) => {
    if (callToActions.length > 1) {
      // Minimum of 1 CTA button
      const updatedActions = callToActions.filter((_, i) => i !== index);
      setCallToActions(updatedActions);
    }
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    const allowedImageTypes = ["image/png", "image/jpeg"];
    const allowedVideoTypes = ["video/mp4"];
    const allowedPdfTypes = ["application/pdf"];

    if (allowedImageTypes.includes(file.type)) {
      setFileType("image");
      setImageFile(file);
      const reader = new FileReader();
      reader.onloadend = () => setPreviewUrl(reader.result); // Convert to base64 for preview
      reader.readAsDataURL(file);
    } else if (allowedVideoTypes.includes(file.type)) {
      setFileType("video");
      setImageFile(file);
      setPreviewUrl(URL.createObjectURL(file)); // Create preview URL for video
    } else if (allowedPdfTypes.includes(file.type)) {
      setFileType("pdf");
      setImageFile(file);
      setPreviewUrl(null); // No preview for PDFs
    } else {
      alert("Only PNG, JPG, MP4, and PDF files are allowed.");
    }
  };

  return (
    <Box
      sx={{ display: "flex", flexDirection: "row", gap: "20px", width: "100%" }}
    >
      {/* Left Side - Form Controls */}
      <Card
        sx={{
          flex: 1,
          height: "100%",
          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
        }}
      >
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Template Configuration
          </Typography>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            <Box sx={{ display: "flex", gap: 2, mb: 3, alignItems: "center" }}>
              <TextField
                fullWidth
                label="Template Name"
                disabled={isViewMode}
                value={templateName}
                onChange={(e) => setTemplateName(e.target.value)}
              />
              <FormControl fullWidth>
                <InputLabel>Template Type</InputLabel>
                <Select
                  disabled={isViewMode}
                  value={templateType}
                  label="Template Type"
                  onChange={(e) => setTemplateType(e.target.value)}
                >
                  <MenuItem value="">Select message type</MenuItem>
                  <MenuItem value="text">Text</MenuItem>
                  <MenuItem value="image">Text With Media</MenuItem>
                </Select>
              </FormControl>
              {templateType === "image" && !isViewMode && (
                <Tooltip title="Upload media">
                  <label
                    style={{
                      cursor: "pointer",
                      color: "#1a73e8",
                      textDecoration: "underline",
                    }}
                  >
                    Upload
                    <input
                      type="file"
                      hidden
                      accept="*"
                      onChange={handleImageUpload}
                    />
                  </label>
                </Tooltip>
              )}
            </Box>

            <Box sx={{ mb: 3 }}>
              <TextField
                fullWidth
                label="Template Format"
                multiline
                rows={4}
                value={templateFormat}
                onChange={(e) => setTemplateFormat(e.target.value)}
                disabled={isViewMode}
                inputProps={{ maxLength: maxChars }}
                helperText={`${
                  templateFormat.length
                }/${maxChars} characters | ${
                  templateFormat.trim().split(/\s+/).filter(Boolean).length
                } words`}
              />
            </Box>

            <Box sx={{ mb: 3 }}>
              <TextField
                fullWidth
                label="Template Footer (Optional)"
                value={templateFooter}
                onChange={(e) => setTemplateFooter(e.target.value)}
                disabled={isViewMode}
                inputProps={{ maxLength: footerMaxChars }}
                helperText={`${templateFooter.length}/${footerMaxChars} characters`}
              />
            </Box>

            <Box sx={{ mb: 3 }}>
              <Typography sx={{ mb: 1 }}>Interactive Actions</Typography>
              <RadioGroup
                value={interactiveAction}
                onChange={handleInteractiveChange}
                row
                disabled={isViewMode}
              >
                <FormControlLabel
                  value="none"
                  control={<Radio disabled={isViewMode} />}
                  label="None"
                />
                <FormControlLabel
                  value="cta"
                  control={<Radio disabled={isViewMode} />}
                  label="Call to Actions"
                />
              </RadioGroup>
            </Box>

            {(interactiveAction === "cta" || interactiveAction === "all") && (
              <Box sx={{ mb: 3 }}>
                {callToActions.map((cta, index) => (
                  <Box
                    key={index}
                    sx={{
                      bgcolor: "#f5f5f5",
                      p: 2,
                      borderRadius: 1,
                      mb: 2,
                      position: "relative",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        mb: 1,
                      }}
                    >
                      <Typography>Call to Action {index + 1}:</Typography>
                      <IconButton
                        size="small"
                        onClick={() => handleRemoveCallToAction(index)}
                        disabled={isViewMode || callToActions.length <= 1}
                      >
                        <X size={16} />
                      </IconButton>
                    </Box>
                    <FormControl fullWidth sx={{ mb: 2 }} disabled={isViewMode}>
                      <InputLabel>Type</InputLabel>
                      <Select
                        value={cta.type}
                        label="Type"
                        onChange={(e) =>
                          handleCallToActionChange(
                            index,
                            "type",
                            e.target.value
                          )
                        }
                      >
                        <MenuItem value="">Select type</MenuItem>
                        <MenuItem value="url">URL</MenuItem>
                        <MenuItem value="phone">Phone Number</MenuItem>
                      </Select>
                    </FormControl>
                    <TextField
                      fullWidth
                      label="Button Title"
                      value={cta.buttonTitle}
                      onChange={(e) =>
                        handleCallToActionChange(
                          index,
                          "buttonTitle",
                          e.target.value
                        )
                      }
                      disabled={isViewMode}
                      inputProps={{ maxLength: buttonTitleMaxChars }}
                      helperText={`${cta.buttonTitle.length}/${buttonTitleMaxChars}`}
                      sx={{ mb: 2 }}
                    />
                    <TextField
                      fullWidth
                      label={cta.type === "phone" ? "Phone Number" : "URL"}
                      value={cta.buttonValue}
                      onChange={(e) =>
                        handleCallToActionChange(
                          index,
                          "buttonValue",
                          e.target.value
                        )
                      }
                      disabled={isViewMode}
                      InputProps={
                        cta.type === "phone" && !isViewMode
                          ? {
                              startAdornment: (
                                <InputAdornment position="start">
                                  <TextField
                                    value={cta.countryCode} // Editable country code
                                    onChange={(e) =>
                                      handleCallToActionChange(
                                        index,
                                        "countryCode",
                                        e.target.value
                                      )
                                    }
                                    size="small"
                                    sx={{ mr: 1 }}
                                    placeholder="Country Code"
                                  />
                                </InputAdornment>
                              ),
                            }
                          : undefined
                      }
                    />
                  </Box>
                ))}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    mb: 1,
                  }}
                >
                  <Button
                    variant="outlined"
                    onClick={handleAddCallToAction}
                    disabled={isViewMode || callToActions.length >= 2}
                    startIcon={<Plus size={16} />}
                    sx={{ mt: 2, height: "30px" }}
                  >
                    Add
                  </Button>
                </Box>
              </Box>
            )}

            {!isViewMode && (
              <Button
                variant="contained"
                color="primary"
                type="submit"
                sx={{ mt: 1 }}
              >
                Submit
              </Button>
            )}
          </form>
        </CardContent>
      </Card>

      {/* Right Side - Preview */}
      <Card
        sx={{
          flex: 1,
          height: "100%",
          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
        }}
      >
        <CardContent>
          <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
            <Typography variant="h6" gutterBottom sx={{ marginRight: "135px" }}>
              Preview
            </Typography>
            {isViewMode && imageFile && (
              <Box
                style={{
                  textAlign: "center",
                  marginTop: "0px",
                  marginBottom: "20px",
                  fontSize: "10px",
                }}
              >
                <button
                  onClick={() => downloadFile(imageFile)}
                  style={{
                    backgroundColor: "#007bff",
                    color: "white",
                    padding: "10px 15px",
                    borderRadius: "5px",
                    border: "none",
                    cursor: "pointer",
                  }}
                >
                  Download File
                </button>
              </Box>
            )}
          </Box>

          <div style={phoneStyles.container}>
            <div style={phoneStyles.dynamicIsland}></div>
            <div style={phoneStyles.screen}>
              <div style={phoneStyles.statusBar}>
                <span>9:41</span>
                <span>100%</span>
              </div>
              <div style={phoneStyles.whatsappHeader}>
                <MessageSquare size={20} />
                <div>
                  <Typography variant="subtitle2">WhatsApp Business</Typography>
                  <Typography variant="caption" sx={{ opacity: 0.7 }}>
                    Template Preview
                  </Typography>
                </div>
              </div>
              <div style={phoneStyles.chatBackground}>
                <div style={phoneStyles.messageBubble}>
                  {templateType === "image" && (
                    <>
                      {fileType === "image" && (
                        <div style={phoneStyles.imagePreview}>
                          {previewUrl ? (
                            <img
                              src={previewUrl}
                              alt="Uploaded"
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                                borderRadius: "8px",
                              }}
                            />
                          ) : (
                            <ImageIcon
                              sx={{ fontSize: 48, color: "#9e9e9e" }}
                            />
                          )}
                        </div>
                      )}
                      {fileType === "video" && (
                        <div style={phoneStyles.imagePreview}>
                          <video
                            controls
                            style={{
                              width: "100%",
                              height: "100%",
                              borderRadius: "8px",
                            }}
                          >
                            <source src={previewUrl} type="video/mp4" />
                            Your browser does not support the video tag.
                          </video>
                        </div>
                      )}
                      {fileType === "pdf" && (
                        <div style={phoneStyles.imagePreview}>
                          <PictureAsPdf sx={{ fontSize: 48, color: "red" }} />
                        </div>
                      )}
                    </>
                  )}
                  <Typography variant="body2" sx={{ whiteSpace: "pre-wrap" }}>
                    {templateFormat || "Your message will appear here"}
                  </Typography>
                  {templateFooter && (
                    <Typography
                      variant="caption"
                      sx={{
                        display: "block",
                        mt: 1,
                        color: "text.secondary",
                      }}
                    >
                      {templateFooter}
                    </Typography>
                  )}
                </div>

                {(interactiveAction === "cta" ||
                  interactiveAction === "all") && (
                  <Box style={phoneStyles.actionButtonWrapper}>
                    {callToActions.map(
                      (cta, index) =>
                        cta.buttonTitle && (
                          <button style={phoneStyles.actionButton} key={index}>
                            {cta.type === "phone" ? (
                              <Phone size={16} style={{ marginRight: 8 }} />
                            ) : (
                              <LinkIcon size={16} style={{ marginRight: 8 }} />
                            )}
                            {cta.buttonTitle}
                          </button>
                        )
                    )}
                  </Box>
                )}

                {(interactiveAction === "quick" ||
                  interactiveAction === "all") &&
                  quickReplies.length > 0 && (
                    <Box style={phoneStyles.actionButtonWrapper}>
                      {quickReplies.map((reply, index) => (
                        <button key={index} style={phoneStyles.quickReply}>
                          {reply}
                        </button>
                      ))}
                    </Box>
                  )}
              </div>
            </div>
          </div>
        </CardContent>
      </Card>
    </Box>
  );
}
