import React from 'react';
import Header from "../../Components/Header";
import Sidebar from "../../Components/Sidebar";
import CustomTabsV2 from "../../Components/CustomTabs";
import AudioApprovalStatus from "./AudioApprovalStatus";
import TemplateApprovalStatus from "./TemplateApprovalStatus";

const MediaApprovalStatus = () => {
    const tabs = [
        { label: 'Audio Approvals', to: 'audio', content: <AudioApprovalStatus /> },
        { label: 'Template Approvals', to: 'template', content: <TemplateApprovalStatus /> },
    ];

    return (
        <>
            <div id="main-wrapper">
                <Header />
                <Sidebar />
                <div className="content-body" style={{ height: "100vh" }}>
                    <div className="page-titles">
                        <ol className="breadcrumb">
                            <li>
                                <h5 className="bc-title">Media Approval Status</h5>
                            </li>
                            <li className="breadcrumb-item">
                                <a href="javascript:void(0)">
                                    Home
                                </a>
                            </li>
                            <li className="breadcrumb-item active">
                                <a href="javascript:void(0)">Media Approval Status</a>
                            </li>
                        </ol>
                    </div>

                    <div className="col-12 bst-seller pt-3" style={{ padding: "30px" }}>
                        <div className="card">
                            <CustomTabsV2 tabs={tabs} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default MediaApprovalStatus;
