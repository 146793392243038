import axios from "axios";
import Header from "../../Components/Header";
import Sidebar from "../../Components/Sidebar";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import ReactAudioPlayer from "react-audio-player"; 
import "./AudioApprovalStatus.css"; // Importing CSS for styling
import moment from "moment";


function AudioApprovalStatus() {
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const token = localStorage.getItem("token");
    const [audioData, setAudioData] = useState([]);

    useEffect(() => {
        getUserAudioData()
    }
        , [])


    var getUserAudioData = () => {
        axios
            .post(
                `${BASE_URL}admin/getUserAudios`,
                {},
                {
                    headers: {
                        "Content-Type": "Application/json",
                        version: "1.0.0",
                        "x-access-token": `${token}`,
                    },
                }
            )
            .then((response) => {
                if (response.data.success == false) {
                    toast.error("unable to load data..please contact admin");
                } else {
                    setAudioData(response.data.data);

                }
            })
            .catch((error) => {
                toast.error("unable to load data..please contact admin");
            });
    }//end of getUserAudioData



    const columns = [
        {
            name: "sr. No.",
            center: true,
            cell: (row, index) => index + 1,
            maxWidth: '7px'
        },
        {
            name: "audio name",
            selector: (row) => row.audio_file_display_name,
        },
        {
            name: "uploaded at",
            selector: (row) => moment(row.upload_date).format("DD-MM-YYYY hh:mm A "),
        },
        {
            name: "link",
            center: true,
            selector: (row) => row.audio_file,
            maxWidth: "10px",

            cell: (row) => <a href={row.audio_file} target="_blank" rel="noopener noreferrer">Link to file</a>
        },
        {
            name: "file",
            center: true,
            cell: (row) => (
            <ReactAudioPlayer
                controls
                src={row.audio_file}
                style={{ width: '100%', maxWidth: '300px', padding: 6 }} // Responsive styling
            />

            ),
        },
        {
            name: "status",
            selector: (row) => row.status,
        }
    ];

    return (

        <div style={{ padding: "30px" }}>
            <div className="card">
                <div className="card-header p-3">
                    <span>
                        <h6 className="d-inline">Audio Approval Status</h6>
                    </span>
                </div>
                <DataTable
                    columns={columns}
                    data={audioData}
                    pagination
                    persistTableHead
                />
            </div>
        </div>
    )

}//end of AudioApprovalStatus

export default AudioApprovalStatus;