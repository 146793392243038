import React, { useState } from 'react';
import { Box, Tabs, Tab, Divider } from '@mui/material';
const CustomTabsV2 = ({
    tabs,
    handleTabChange = () => { },
}) => {
    const [activeTab, setActiveTab] = useState(tabs[0].to); // Track the active tab

    const handleTabClick = (event, newValue) => {
        setActiveTab(newValue); // Update active tab state
        const selectedTab = tabs.find(tab => tab.to === newValue);
        if (selectedTab) {
            handleTabChange(selectedTab.label, selectedTab.to);
        }
    };

    return (
        <Box sx={{ width: '100%', borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
                value={activeTab}
                onChange={handleTabClick}
                variant="scrollable"
                scrollButtons="auto"
                indicatorColor="primary"
                sx={{ paddingLeft: "20px", paddingTop: "10px" }}
            >
                {tabs.map(tab => (
                    <Tab
                    style={{textTransform: "none"}}
                        key={tab.label}
                        label={tab.label}
                        value={tab.to}
                        sx={{
                            paddingLeft: "10px",
                            fontWeight: activeTab === tab.to ? 'bold' : 'normal',
                            color: activeTab === tab.to ? 'blue' : '',  // Active tab color
                        }}
                    />
                ))}
            </Tabs>
            {/* Render the content below the tabs */}
            <Box sx={{ p: 1 }}>
                {tabs.map(tab => (
                    activeTab === tab.to && (
                        <div key={tab.label}>
                            {tab.content} {/* Render the content of the active tab */}
                        </div>
                    )
                ))}
            </Box>
        </Box>
    );
};
export default CustomTabsV2;
