import React, { useState } from 'react';
import { Box, Tabs, Tab, Divider } from '@mui/material';
import AdminAudioApproval from '../Pages/Admin/AdminAudioApproval';
import NavHeader from './Header';
import Sidebar from './Sidebar';
import CustomTabsV2 from './CustomTabs';
import TemplateApprovalStatus from '../Pages/Reseller/TemplateApprovalStatus';

const MediaApproval = () => {
    const tabs = [
        { label: 'Voice', to: 'voice', content: <AdminAudioApproval /> },
        { label: 'Whatsapp', to: 'email', content: <TemplateApprovalStatus/> },
    ];

    return (
        <>
            <div id="main-wrapper">
                <NavHeader />
                <Sidebar />
                <div className="content-body" style={{ height: "100vh" }}>
                    <div className="page-titles">
                        <ol className="breadcrumb">
                            <li>
                                <h5 className="bc-title">Media Approvals</h5>
                            </li>
                            <li className="breadcrumb-item">
                                <a href="javascript:void(0)">
                                    Home
                                </a>
                            </li>
                            <li className="breadcrumb-item active">
                                <a href="javascript:void(0)">Media Approvals</a>
                            </li>
                        </ol>
                    </div>

                    <div className="col-12 bst-seller pt-5" style={{ padding: "30px" }}>
                        <div className="card">
                            <CustomTabsV2 tabs={tabs} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default MediaApproval;
