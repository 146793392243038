import axios from "axios";
import Header from "../../Components/Header";
import Sidebar from "../../Components/Sidebar";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";

const WebsiteOnOff = () => {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const initialState = {
    name: "",
    email: "",
    favicon_icon: "",
    logo: "",
    phone_no: "",
    title: "",
    description: "",
    status: "Active",
    background_image: "", // Added background image state
  };

  const [webSiteInfo, setWebSiteInfo] = useState(initialState);
  const [logo, setLogo] = useState(undefined);
  const [faviconIcon, setFaviconIcon] = useState(undefined);
  const [backgroundImage, setBackgroundImage] = useState(undefined); // State for background image
  const [id, setId] = useState("");

  const handleChange = (e) => {
    setWebSiteInfo({ ...webSiteInfo, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    getWebsiteInfo();
    const theme = localStorage.getItem("theme");
    document.body.className = theme;
  }, []);

  const getWebsiteInfo = () => {
    const token = localStorage.getItem("token");
    axios
      .get(`${BASE_URL}admin/getWebsiteInfo`, {
        headers: {
          "x-access-token": `${token}`,
          version: "1.0.0",
          "Content-Type": "Application/json",
        },
      })
      .then((response) => {
        if (response.data.data == false) {
          // toast.error(response.data.message);
        } else {
          // toast.success(response.data.message);
          setWebSiteInfo(response.data.data);
          setId(response.data.data._id);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const Submit = () => {
    const token = localStorage.getItem("token");
    let formData = new FormData();
    formData.append("id", id);
    formData.append("name", webSiteInfo.name);
    formData.append("email", webSiteInfo.email);
    formData.append("status", webSiteInfo.status);

    if (faviconIcon !== undefined) {
      formData.append("favicon_icon", faviconIcon[0]);
    }

    if (logo !== undefined) {
      formData.append("logo", logo[0]);
    }

    if (backgroundImage !== undefined) {
      // Append background image to form data
      formData.append("background_image", backgroundImage[0]);
    }

    formData.append("phone_no", webSiteInfo.phone_no);
    formData.append("title", webSiteInfo.title);
    formData.append("description", webSiteInfo.description);

    axios
      .post(`${BASE_URL}admin/addUpdateWebsiteInfo`, formData, {
        headers: {
          "x-access-token": `${token}`,
          version: "1.0.0",
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        if (response.data.success === false) {
          toast.error(response.data.message);
        } else {
          toast.success(response.data.message);
          getWebsiteInfo();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <div id="main-wrapper">
        <Header />
        <Sidebar />
        <div className="content-body">
          <div className="page-titles">
            <ol className="breadcrumb">
              <li>
                <h5 className="bc-title">Website On OFF</h5>
              </li>
              <li className="breadcrumb-item">
                <a href="javascript:void(0)">Home</a>
              </li>
              <li className="breadcrumb-item active">
                <a href="javascript:void(0)">Website On OFF:-</a>
              </li>
            </ol>
          </div>
          <div className="pt-4" style={{ height: "100vh", padding: "30px" }}>
            <div className="card">
              <div className="card-header ps-4 p-3">Website On Off</div>
              <div className="card-body">
                <div className="row">
                  <div className="col-3 text-center">
                    <label className="mt-3">Title :</label>
                  </div>
                  <div className="col-5 mt-3">
                    <input
                      type="text"
                      value={webSiteInfo.title}
                      onChange={handleChange}
                      name="title"
                      className="form-control inputType"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-3 text-center">
                    <label className="mt-3">Description :</label>
                  </div>
                  <div className="col-5 mt-3">
                    <input
                      type="text"
                      name="description"
                      onChange={handleChange}
                      value={webSiteInfo.description}
                      className="form-control inputType"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-3 text-center">
                    <label className="mt-3">Website name :</label>
                  </div>
                  <div className="col-5 mt-3">
                    <input
                      type="text"
                      name="name"
                      onChange={handleChange}
                      value={webSiteInfo.name}
                      className="form-control inputType"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-3 text-center">
                    <label className="mt-3">Website Email :</label>
                  </div>
                  <div className="col-5 mt-3">
                    <input
                      type="email"
                      name="email"
                      onChange={handleChange}
                      value={webSiteInfo.email}
                      className="form-control inputType"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-3 text-center">
                    <label className="mt-3">Phone Number :</label>
                  </div>
                  <div className="col-5 mt-3">
                    <input
                      type="text"
                      name="phone_no"
                      onChange={handleChange}
                      value={webSiteInfo.phone_no}
                      className="form-control inputType"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-3 text-center">
                    <label className="mt-3">Website logo</label>
                  </div>
                  <div className="col-5 mt-3">
                    <input
                      type="file"
                      className="form-control inputType"
                      onChange={(e) => setLogo(e.target.files)}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-3 text-center">
                    <label className="mt-3">Website Favicon Icon</label>
                  </div>
                  <div className="col-5 mt-3">
                    <input
                      type="file"
                      className="form-control inputType"
                      onChange={(e) => setFaviconIcon(e.target.files)}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-3 text-center">
                    <label className="mt-3">Login Page Background Image</label>
                  </div>
                  <div className="col-5 mt-3">
                    <input
                      type="file"
                      className="form-control inputType"
                      onChange={(e) => setBackgroundImage(e.target.files)} // Handle background image change
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-3 text-center ">
                    <label className="mt-3">Status:</label>
                  </div>
                  <div className="col-5">
                    <select
                      className="form-control mt-3 inputType"
                      name="status"
                      onChange={handleChange}
                    >
                      <option value="">-select-</option>
                      <option
                        selected={
                          webSiteInfo.status === "Active" ? true : false
                        }
                        value="Active"
                      >
                        Active
                      </option>
                      <option
                        selected={
                          webSiteInfo.status === "InActive" ? true : false
                        }
                        value="InActive"
                      >
                        InActive
                      </option>
                    </select>
                    <div className="mt-3">
                      <button
                        type="submit"
                        onClick={() => Submit()}
                        className="btn btn-success"
                      >
                        Update
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default WebsiteOnOff;
