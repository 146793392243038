import axios from "axios";
import React from "react";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";

import Header from "../../Components/Header";
import Sidebar from "../../Components/Sidebar";

const SendAudioCampaign = () => {
  const initialState = {
    // mobile_no: "",
    // audio: "",
    // count: "",
    groupimport: "",
    txtimport: "",
    csvimport: "",
  };
  const [numbercounter, setNumberCounter] = useState("");
  const [mobile_no, setMobile_No] = useState("");
  const [count, setCount] = useState(0);
  const [audios, setAudios] = useState([]);
  const [audioId, setAudioId] = useState("");
  const [responseNumber, setResponseNumber] = useState("");
  const [contactGroupData, setContactGroupData] = useState([]);
  const [values, setValues] = useState(initialState);
  const [isloading, setLoading] = useState(false);
  const maxFileSize = 5000000;

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const BASE_URL = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    const theme = localStorage.getItem("theme");
    document.body.className = theme;
    if (numbercounter.length == 0) {
      setNumberCounter(0);
    }
  }, [count]);

  const RowCount = (text) => {
    console.log(text);
    var lines = text.split(/\r|\r\n|\n/);
    console.log(lines);
    setCount(lines.length);
    // console.log("count", count);
    setNumberCounter(count);
    setMobile_No(text);
  };

  const GroupRowCount = (text) => {
    console.log(text);
    // return;
    var lines = text.split(/\r|\r\n|\n/);
    console.log(lines);
    setCount(lines.length);
    // console.log("count", count);
    setNumberCounter(count);
    setMobile_No(text);
  };

  const OnSubmit = () => {
    setLoading(true);
    if (!audioId) {
      toast.error("audio is mandatory");
      setLoading(false);
      return;
    }

    const token = localStorage.getItem("token");
    axios
      .post(
        `${BASE_URL}admin/addSendMessage`,
        {
          contact_no: mobile_no,
          msg_count: count,
          audio_id: audioId,
          response_number: responseNumber,
          media_type:"VOICE"
        },
        {
          headers: {
            "x-access-token": `${token}`,
            "Content-Type": "Application/json",
            version: "1.0.0",
          },
        }
      )
      .then((response) => {
        if (response.data.success == false) {
          toast.error(response.data.message);
          setLoading(false);
        } else {
          toast.success(response.data.message);
          setLoading(false);
          setNumberCounter("");
          setMobile_No("");
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };
  const allowedExtensions = ["csv"];
  const handleFileChange = (e) => {
    // $('#thefile').change(function(e) {
    if (e.target.files != undefined) {
      var reader = new FileReader();
      var theInfo = "";
      var file = document.getElementById("thefile").files[0];
      reader.onload = function (e) {
        var lenlen = e.target.result.split("\n").length;
        for (let i = 0; i < lenlen; i++) {
          theInfo += e.target.result.split("\n")[i] + "\n";
        }
        // $('#res3').val('');
        // $('#res3').val(theInfo);
        // countLines();
        // $('#import-txt').fadeOut();
        // $('#import-group').fadeOut();
        // MNumber();
      };
      reader.readAsText(e.target.files.item(0));
    }
  };

  // const uploadAudio=(e)=>{
  //   if(audio.size>maxFileSize)
  //   {
  //     toast.error("file should be of 5 MB or Less");
  //   }
  //   setAudio(e.target.value)
  // }
  const showFile = async (e) => {
    e.preventDefault();
    const reader = new FileReader();
    reader.onload = async (e) => {
      let text = e.target.result;
      text = text.trim();
      var lines = text.split(/\r|\r\n|\n/);

      lines = lines.filter(function (el) {
        return el != "";
      });
      setCount(lines.length);
      setNumberCounter(count);
      setMobile_No(text);
    };
    reader.readAsText(e.target.files[0]);
  };

  const showCSVFile = async (e) => {
    e.preventDefault();
    const reader = new FileReader();
    reader.onload = async (e) => {
      let text = e.target.result;
      text = text.trim();
      console.log("text ==>", text);
      // alert(text)
      var lines = text.split(/\r|\r\n|\n/);

      lines = lines.filter(function (el) {
        return el != "";
      });
      console.log("line ==>", lines);

      setCount(lines.length);
      // console.log("count", count);
      setNumberCounter(count);
      console.log(count);
      setMobile_No(text);
    };
    reader.readAsText(e.target.files[0]);
  };

  const getApprovedAudios = () => {
    const token = localStorage.getItem("token");
    axios
      .get(`${BASE_URL}admin/getUserApprovedAudios`, {
        headers: {
          "x-access-token": `${token}`,
          version: "1.0.0",
          "Content-Type": "Application/json",
        },
      })
      .then((response) => {
        setAudios(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }; //end of GetAllAudios

  const GetAllContactGroup = () => {
    const token = localStorage.getItem("token");
    axios
      .post(
        `${BASE_URL}admin/getAllContactGroup`,
        { page: 1 },
        {
          headers: {
            "x-access-token": `${token}`,
            version: "1.0.0",
            "Content-Type": "Application/json",
          },
        }
      )
      .then((response) => {
        if (response.data.data == false) {
          // toast.error(response.data.message);
        } else {
          // toast.success(response.data.message);
          setContactGroupData(response.data.data);
          console.log("transactionlog response:", response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getApprovedAudios();

    GetAllContactGroup();
    const theme = localStorage.getItem("theme");
    document.body.className = theme;
  }, []);

  return (
    <div style={{ padding: "30px" }}>
      <div className="card">
        <div className="card-header p-3">
          <span>
            <i className="fa fa-pen"></i>&nbsp;&nbsp;
            <h6 className="d-inline">Send Voice SMS</h6>
          </span>
        </div>
        <div className="card-body">
          <div className="row m-3">
            <div className="col-3 col-xs-3 col-sm-3 col-md-3 col-lg-2">
              <label className="m-2">Enter Mobile Number:-</label>
            </div>
            <div className="col-9 col-xs-9 col-sm-9 col-md-9 col-lg-4  ">
              <textarea
                rows={4}
                cols={4}
                className="form-control inputType"
                placeholder="Enter 10 digits mobile number here"
                onChange={(e) => RowCount(e.target.value)}
                name="mobile_no"
                value={mobile_no}
                // onChange={handleChange}
              ></textarea>
            </div>

            <div className="col col-6">
              <div className="row ">
                <label
                  className=" col-3 col-xs-3 col-sm-3 col-md-3 col-lg-2 mt-4"
                  style={{ width: "250px" }}
                >
                  Select Audio :-
                </label>
                <select
                  style={{ maxWidth: "400px" }}
                  className=" form-select inputType  mt-2"
                  onChange={(e) => setAudioId(e.target.value)}
                >
                  <option disabled selected>
                    Select audio
                  </option>
                  {audios.map((audio) => (
                    <option value={audio.audio_id}>
                      {audio.audio_file_display_name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="row ">
                <label
                  className=" col-3 col-xs-3 col-sm-3 col-md-3 col-lg-2 mt-4"
                  style={{ width: "250px" }}
                >
                  Select Response Key :-{" "}
                </label>
                <select
                  style={{ width: "400px" }}
                  className="form-select inputType mt-2"
                  onChange={(e) => setResponseNumber(e.target.value)}
                >
                  <option disabled selected>
                    Select Response Key
                  </option>
                  {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((number) => (
                    <option>{number}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          <div className="row m-3">
            <div className="col-3 col-xs-3 col-sm-3 col-md-3 col-lg-2">
              <label className="m-2">Count:-</label>
            </div>
            <div className="col-9 col-xs-9 col-sm-9 col-md-9 col-lg-4">
              <input
                type="text"
                className="form-control inputType"
                name="numbercounter"
                value={count}
                disabled
                // onChange={handleChange}
              />
            </div>
            <div className="col-3 col-xs-3 col-sm-3 col-md-3 col-lg-2 mt-2"></div>
            <div className="col-9 col-xs-9 col-sm-9 col-md-9 col-lg-4 mt-2"></div>
          </div>

          <div className="row m-3">
            <div className="col-3 col-xs-3 col-sm-3 col-md-3 col-lg-2">
              <label className="m-2">Group Import</label>
            </div>
            <div className="col-9 col-xs-9 col-sm-9 col-md-9 col-lg-4">
              <select
                className="form-control inputType"
                onChange={(e) => GroupRowCount(e.target.value)}
              >
                <option className="">--select--</option>
                {contactGroupData &&
                  contactGroupData.map((item, index) => (
                    <option key={index} value={item.contact_no}>
                      {item.group_name}
                    </option>
                  ))}
              </select>
            </div>
            <div className="col-3 col-xs-3 col-sm-3 col-md-3 col-lg-2 mt-2"></div>
            <div className="col-9 col-xs-9 col-sm-9 col-md-9 col-lg-4 mt-2"></div>
          </div>

          <div className="row m-3">
            <div className="col-3 col-xs-3 col-sm-3 col-md-3 col-lg-2">
              <label className="m-2">TXT Import</label>
            </div>
            <div className="col-9 col-xs-9 col-sm-9 col-md-9 col-lg-4">
              <input
                type="file"
                className="form-control inputType"
                // onChange={(e) => handleFileChange(e)}
                onChange={(e) => showFile(e)}
                id="csvInput"
                name="file"
              />
            </div>
            {/* <div className="col-3 col-xs-3 col-sm-3 col-md-3 col-lg-2 mt-2">

                                    </div>
                                    <div className="col-9 col-xs-9 col-sm-9 col-md-9 col-lg-4 mt-2">

                                    </div> */}
          </div>

          <div className="row m-3">
            <div className="col-3 col-xs-3 col-sm-3 col-md-3 col-lg-2">
              <label className="m-2">CSV Import</label>
            </div>
            <div className="col-9 col-xs-9 col-sm-9 col-md-9 col-lg-4">
              <input
                type="file"
                className="form-control inputType"
                onChange={(e) => showCSVFile(e)}
              />
            </div>
            <div className="col-3 col-xs-3 col-sm-3 col-md-3 col-lg-2 mt-2"></div>
            <div className="col-9 col-xs-9 col-sm-9 col-md-9 col-lg-4 mt-2"></div>
          </div>

          <div className="d-flex justify-content-center align-items-center m-5">
            {" "}
            {isloading ? (
              <button
                type="button"
                className="btn btn-primary  inputType"
                // onClick={() => OnSubmit()}
              >
                <i
                  className="fa fa-spinner fa-spin"
                  style={{ fontSize: "25px" }}
                ></i>
              </button>
            ) : (
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => OnSubmit()}
              >
                Submit
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default SendAudioCampaign;
