import axios from "axios";
import { toast } from "react-toastify";
import { useCallback, useEffect, useState } from "react";
import moment from "moment";
import { debounce } from "lodash";

function AdminAudioApproval() {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [showUserDetails, setShowUserDetails] = useState(false);
  const [audios, setAudios] = useState([]);
  const [statusFilter, setStatusFilter] = useState("all");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchText, setSearchText] = useState("");
  const limit = 10;

  const getAllAudiosDetails = (searchString) => {
    const token = localStorage.getItem("token");
    axios
      .post(
        `${BASE_URL}admin/getAllAudiosDetails`,
        {
          page: currentPage,
          limit: limit,
          statusFilter: statusFilter,
          searchText: searchString ? searchString : searchText,
        },
        {
          headers: {
            "x-access-token": `${token}`,
            "Content-Type": "Application/json",
            version: "1.0.0",
          },
        }
      )
      .then((response) => {
        if (!response.data.success) {
          toast.error(response.data.message);
        } else {
          setAudios(response.data.data.data);
          setTotalPages(response.data.data.totalPages);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    getAllAudiosDetails();
  }, [currentPage]);

  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchText(value);
    setCurrentPage(1);
    debouncedSearch(value);
  };

  const debouncedSearch = useCallback(
    debounce((query) => {
      getAllAudiosDetails(query);
    }, 500),
    []
  );

  var acceptReject = (audio_id, modifiedStatus) => {
    const token = localStorage.getItem("token");

    if (modifiedStatus === "approved") {
      const uniqueListId = window.prompt(
        "Please enter the unique list ID for this audio:"
      );

      if (!uniqueListId) {
        toast.error("Approval canceled: Unique list ID is required.");
        return;
      }

      axios
        .post(
          `${BASE_URL}admin/changeAudioStatus`,
          {
            audio_id: audio_id,
            modified_status: modifiedStatus,
            list_id: uniqueListId,
          },
          {
            headers: {
              "x-access-token": `${token}`,
              "Content-Type": "application/json",
              version: "1.0.0",
            },
          }
        )
        .then((response) => {
          setAudios((prevAudios) =>
            prevAudios.map((audio) => {
              if (audio.audio_id === audio_id) audio.status = modifiedStatus;
              return audio;
            })
          );
          toast.success(response.data.message);
          // Recalling API after edit
          getAllAudiosDetails();
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      axios
        .post(
          `${BASE_URL}admin/changeAudioStatus`,
          {
            audio_id: audio_id,
            modified_status: modifiedStatus,
          },
          {
            headers: {
              "x-access-token": `${token}`,
              "Content-Type": "application/json",
              version: "1.0.0",
            },
          }
        )
        .then((response) => {
          setAudios((prevAudios) =>
            prevAudios.map((audio) => {
              if (audio.audio_id === audio_id) audio.status = modifiedStatus;
              return audio;
            })
          );
          toast.success(response.data.message);
          // Recalling API after edit
          getAllAudiosDetails();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const audioActions = (audio) => {
    return (
      <div>
        <div className="d-flex gap-2" style={{ justifyContent: "center" }}>
          {audio.status !== "pending" && (
            <button
              title="Change status to pending"
              className="btn btn-outline-warning btn-sm d-flex align-items-center gap-1 px-3 shadow-sm"
              onClick={() => acceptReject(audio.audio_id, "pending")}
            >
              <i className="fa-solid fa-circle-exclamation"></i>
            </button>
          )}
          {audio.status !== "approved" && (
            <button
              title="Approve"
              className="btn btn-outline-success btn-sm d-flex align-items-center gap-1 px-3 shadow-sm"
              onClick={() => acceptReject(audio.audio_id, "approved")}
            >
              <i className="fa-solid fa-check"></i>
            </button>
          )}
          {audio.status !== "rejected" && (
            <button
              title="Reject"
              className="btn btn-outline-danger btn-sm d-flex align-items-center gap-1 px-3 shadow-sm"
              onClick={() => acceptReject(audio.audio_id, "rejected")}
            >
              <i className="fa-regular fa-circle-xmark"></i>
            </button>
          )}
          <button
            style={{ height: "40px", borderRadius: "4px" }}
            className="btn btn-danger btn-sm "
            onClick={() => deleteAudio(audio._id)}
          >
            <i class="bi bi-trash"></i>
          </button>
        </div>
      </div>
    );
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const preNextBtn = (displayText, compareToHide) => {
    return (
      <div
        style={{
          margin: "10px",
          fontSize: "1.2rem",
          cursor: "pointer",
          display: currentPage == compareToHide ? "none" : "block",
        }}
        onClick={() => {
          console.log("here");
          handlePageChange(currentPage - (compareToHide == 1 ? 1 : -1));
        }}
      >
        {displayText}
      </div>
    );
  };

  const deleteAudio = (id) => {
    let confirm = window.confirm("Are you sure?");

    // console.log(confirm);
    if (confirm === false) {
      return;
    }

    const token = localStorage.getItem("token");
    axios
      .delete(`${BASE_URL}admin/deleteAudio/${id}`, {
        headers: {
          "Content-Type": "Application/json",
          version: "1.0.0",
          "x-access-token": `${token}`,
        },
      })
      .then((response) => {
        if (response.data.success == false) {
          toast.error(response.data.message);
        } else {
          toast.error(response.data.message);
          getAllAudiosDetails();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <div className="col-12 bst-seller pt-1" style={{ padding: "10px" }}>
        <div className="card">
          <div className="card-header p-3">
            <h4 className="heading mb-0">Audio List</h4>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              padding: "4px",
            }}
          >
            <input
              type="text"
              className="form-control"
              placeholder="Search text here"
              value={searchText}
              style={{ alignSelf: "flex-end", maxWidth: "300px" }}
              onChange={handleSearch}
            />
          </div>

          <div className="card-body p-0">
            <table
              className="table table-bordered table-responsive"
              style={{ textAlign: "center", tableLayout: "fixed" }}
            >
              <thead>
                <tr>
                  <th style={{ verticalAlign: "top", width: "5%" }}>Id</th>
                  <th style={{ verticalAlign: "top", width: "15%" }}>Name</th>
                  <th style={{ verticalAlign: "top", width: "20%" }}>Audio</th>
                  <th style={{ verticalAlign: "top", width: "20%" }}>
                    <div>
                      Uploaded by <br />
                      <input
                        type="checkbox"
                        id="showuserdetailscheckbox"
                        className="form-check-input"
                        value="show details"
                        checked={showUserDetails}
                        onChange={() => {
                          setShowUserDetails(!showUserDetails);
                        }}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="showuserdetailscheckbox"
                      >
                        Show user details
                      </label>
                    </div>
                  </th>
                  <th style={{ verticalAlign: "top", width: "15%" }}>
                    Uploaded at
                  </th>
                  <th style={{ verticalAlign: "top", width: "10%" }}>
                    Status / List Id
                  </th>
                  <th style={{ verticalAlign: "top", width: "15%" }}>
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {audios.map((audio, index) => {
                  return (
                    <tr key={index}>
                      <td>{audio.audio_id}</td>
                      <td
                        style={{
                          wordWrap: "break-word",
                          whiteSpace: "normal",
                        }}
                      >
                        {audio.audio_file_display_name}
                      </td>
                      <td>
                        <div style={{ width: "100%", overflow: "hidden" }}>
                          <audio controls style={{ width: "100%" }}>
                            <source src={audio.audio_file} />
                            Your browser does not support the audio element.
                          </audio>
                        </div>
                      </td>
                      <td
                        style={{
                          wordWrap: "break-word",
                          whiteSpace: "normal",
                        }}
                      >
                        <div>
                          {audio.uploaded_by_user.name}
                          {showUserDetails && (
                            <>
                              <br />
                              {`(${audio.uploaded_by_user.email})`}
                              <br />
                              {`(${audio.uploaded_by_user.mobile_no})`}
                            </>
                          )}
                        </div>
                      </td>
                      <td
                        style={{
                          wordWrap: "break-word",
                          whiteSpace: "normal",
                        }}
                      >
                        <div>
                          {moment(audio.upload_date).format(
                            "DD-MM-YYYY hh:mm A"
                          )}
                        </div>
                      </td>
                      <td>
                        {audio.status === "approved"
                          ? audio.list_id
                          : audio.status}
                      </td>
                      <td
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {audioActions(audio)}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <div
              className="d-flex justify-content-end align-items-center"
              style={{ padding: "5px" }}
            >
              {preNextBtn("<<", 1)}
              <div style={{ marginRight: "10px" }}>
                page {currentPage} of {totalPages}
              </div>
              {preNextBtn(">>", totalPages)}
              <div>
                <select
                  className="form-select"
                  onChange={(e) => handlePageChange(e.target.value)}
                >
                  <option disabled selected>
                    go to page
                  </option>
                  {Array(totalPages)
                    .fill()
                    .map((_, index) => index + 1)
                    .map((pageNo) => (
                      <option disabled={currentPage == pageNo} value={pageNo}>
                        {pageNo}
                      </option>
                    ))}
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default AdminAudioApproval;
