import axios from "axios";
import { toast } from "react-toastify";
import { useEffect, useState, useCallback } from "react";
import WhatsAppMessagePreview from "../../Components/WhatsAppMessagePreview";
import moment from "moment";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Modal, Button } from "react-bootstrap";
import { debounce } from "lodash";

function TemplateApprovalStatus() {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const token = localStorage.getItem("token");
  const role = localStorage.getItem("role");

  const [templateData, setTemplateData] = useState([]);
  const [showUserDetails, setShowUserDetails] = useState(false);

  const [loading, setLoading] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [selectedTemplateId, setSelectedTemplateId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [copiedTemplateData, setCopiedTemplateData] = useState("");

  const [statusFilter, setStatusFilter] = useState("all");
  const limit = 10;

  useEffect(() => {
    getUserTemplates();
  }, [currentPage]);

  const getUserTemplates = (searchString) => {
    setLoading(true);
    axios
      .post(
        `${BASE_URL}admin/getAllTemplates`,
        {
          page: currentPage,
          limit,
          statusFilter: statusFilter,
          searchText: searchString || searchText,
        },
        {
          headers: {
            "Content-Type": "Application/json",
            version: "1.0.0",
            "x-access-token": token,
          },
        }
      )
      .then((response) => {
        setLoading(false);
        if (!response.data.success) {
          toast.error("Unable to load templates, please contact admin");
        } else {
          setTemplateData(response.data.data.data);
          setTotalPages(response.data.data.totalPages);
        }
      })
      .catch(() => {
        setLoading(false);
        toast.error("Unable to load templates, please contact admin");
      });
  };

  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchText(value);
    setCurrentPage(1);
    debouncedSearch(value);
  };

  const debouncedSearch = useCallback(
    debounce((query) => getUserTemplates(query), 500),
    []
  );

  const handlePreview = (templateId) => {
    setSelectedTemplateId(templateId);
    setShowPreview(true);
  };

  const handleClosePreview = () => {
    setShowPreview(false);
    setSelectedTemplateId(null);
  };

  const handleStatusChange = async (templateId, status) => {
    const token = localStorage.getItem("token");
    let campaignName = null;
    if (status === "approved") {
      campaignName = window.prompt("Please enter a unique campaign name:");

      if (!campaignName) {
        toast.error("Approval canceled: Campaign name is required.");
        return;
      }
    }
    axios
      .post(
        `${BASE_URL}admin/updateTemplateStatus`,
        { templateId, status, campaign_name: campaignName },
        {
          headers: {
            "Content-Type": "application/json",
            version: "1.0.0",
            "x-access-token": token,
          },
        }
      )
      .then((response) => {
        if (!response.data.success) {
          toast.error("Unable to update template status, please contact admin");
        } else {
          getUserTemplates();
          toast.success("Template status updated successfully!");
        }
      })
      .catch(() => {
        toast.error("Unable to update template status, please contact admin");
      });
  };

  const getTemplateData = async (row_id) => {
    try {
      const response = await axios.get(
        `${BASE_URL}admin/getUserTemplate/` + row_id,
        {
          headers: {
            "Content-Type": "Application/json",
            version: "1.0.0",
            "x-access-token": `${token}`,
          },
        }
      );

      if (!response.data.success) {
        toast.error("Unable to load templates..please contact admin");
        return;
      }

      const {
        templateName,
        templateType,
        templateFormat,
        templateFooter,
        callToActions,
        fileUrl,
      } = response.data.data;

      // Format callToActions as readable JSON
      const formattedCallToActions = callToActions.map((action) => ({
        type: action.type,
        title: action.buttonTitle,
        value: action.buttonValue,
      }));

      // Create the final object with relevant details
      const usefulData = {
        templateName,
        templateType,
        templateFormat,
        templateFooter,
        callToActions: formattedCallToActions,
        fileUrl: BASE_URL + fileUrl,
      };

      setCopiedTemplateData(JSON.stringify(usefulData)); // Pretty JSON format for readability
    } catch (error) {
      toast.error("Unable to load templates..please contact admin");
    }
  };
  const deleteTemplate = async (templateId) => {
    await axios
      .delete(`${BASE_URL}admin/whatsapp-template/` + templateId, {
        headers: {
          "Content-Type": "Application/json",
          version: "1.0.0",
          "x-access-token": token,
        },
      })
      .then((response) => {
        if (!response.data.success) {
          toast.error("Unable to delete template");
        } else {
          getUserTemplates();
          toast.success("Template deleted successfully");
        }
      })
      .catch(() => {
        toast.error("Unable to delete template");
      });
  };

  const preNextBtn = (displayText, compareToHide) => {
    return (
      <div
        style={{
          margin: "10px",
          fontSize: "1.2rem",
          cursor: "pointer",
          display: currentPage == compareToHide ? "none" : "block",
        }}
        onClick={() => {
          console.log("here");
          handlePageChange(currentPage - (compareToHide == 1 ? 1 : -1));
        }}
      >
        {displayText}
      </div>
    );
  };

  return (
    <div className="col-12 bst-seller pt-1" style={{ padding: "10px" }}>
      <div className="card">
        <div className="card-header p-3">
          <h4 className="heading mb-0">Whatsapp Templates</h4>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            padding: "4px",
          }}
        >
          <input
            type="text"
            className="form-control"
            placeholder="Search text here"
            value={searchText}
            style={{ alignSelf: "flex-end", maxWidth: "300px" }}
            onChange={handleSearch}
          />
        </div>

        <div className="card-body p-0">
          <table
            className="table table-bordered table-responsive"
            style={{ textAlign: "center", tableLayout: "fixed" }}
          >
            <thead>
              <tr>
                <th style={{ verticalAlign: "top", width: "5%" }}>Sr. No.</th>
                <th style={{ verticalAlign: "top", width: "20%" }}>
                  Template Name
                </th>
                {role === "3" && (
                  <th style={{ verticalAlign: "top", width: "20%" }}>
                    <div>
                      Uploaded by <br />
                      <input
                        type="checkbox"
                        id="showuserdetailscheckbox"
                        className="form-check-input"
                        value="show details"
                        checked={showUserDetails}
                        onChange={() => setShowUserDetails(!showUserDetails)}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="showuserdetailscheckbox"
                      >
                        Show user details
                      </label>
                    </div>
                  </th>
                )}
                <th style={{ verticalAlign: "top", width: "15%" }}>
                  Created At
                </th>
                <th
                  style={{
                    verticalAlign: "top",
                    width: "18%",
                    wordWrap: "break-word",
                    whiteSpace: "normal",
                  }}
                >
                  {role === "3" ? "Status / Campaign Name" : "Status"}
                </th>

                <th style={{ verticalAlign: "top", width: "8%" }}>Preview</th>

                {role === "3" && (
                  <>
                    <th
                      style={{
                        verticalAlign: "top",
                        horizontalAlign: "center",
                        width: "20%",
                      }}
                    >
                      Actions
                    </th>
                    <th style={{ verticalAlign: "top", width: "10%" }}>
                      Delete
                    </th>
                  </>
                )}
              </tr>
            </thead>
            <tbody>
              {templateData.map((row, index) => (
                <tr key={row._id}>
                  <td>{index + 1}</td>
                  <td>{row.templateName}</td>
                  {role === "3" && (
                    <td
                      style={{
                        wordWrap: "break-word",
                        whiteSpace: "normal",
                      }}
                    >
                      <div>
                        {row.uploaded_by_user.name}
                        {showUserDetails && (
                          <>
                            <br />
                            {`(${row.uploaded_by_user.email})`}
                            <br />
                            {`(${row.uploaded_by_user.mobile_no})`}
                          </>
                        )}
                      </div>
                    </td>
                  )}
                  <td>
                    {moment(row.createdDate).format("DD-MM-YYYY hh:mm A")}
                  </td>
                  <td>
                    {row.approvalStatus === "approved"
                      ? role === "3"
                        ? row?.campaign_name
                        : row.approvalStatus
                      : row.approvalStatus}
                  </td>

                  <td>
                    <button
                      className="btn btn-primary btn-sm"
                      onClick={() => handlePreview(row._id)}
                    >
                      View
                    </button>
                  </td>
                  {role === "3" && (
                    <>
                      <td>
                        <div
                          className="d-flex gap-2"
                          style={{ justifyContent: "center" }}
                        >
                          {row.approvalStatus !== "pending" && (
                            <button
                              title="Change status to pending"
                              className="btn btn-outline-warning btn-sm d-flex align-items-center gap-1 px-3 shadow-sm"
                              onClick={() =>
                                handleStatusChange(row._id, "pending")
                              }
                            >
                              <i className="fa-solid fa-circle-exclamation"></i>{" "}
                              Pending
                            </button>
                          )}
                          {row.approvalStatus !== "approved" && (
                            <button
                              title="Approve"
                              className="btn btn-outline-success btn-sm d-flex align-items-center gap-1 px-3 shadow-sm"
                              onClick={() =>
                                handleStatusChange(row._id, "approved")
                              }
                            >
                              <i className="fa-solid fa-check"></i> Approve
                            </button>
                          )}
                          {row.approvalStatus !== "rejected" && (
                            <button
                              title="Reject"
                              className="btn btn-outline-danger btn-sm d-flex align-items-center gap-1 px-3 shadow-sm"
                              onClick={() =>
                                handleStatusChange(row._id, "rejected")
                              }
                            >
                              <i className="fa-regular fa-circle-xmark"></i>{" "}
                              Reject
                            </button>
                          )}
                        </div>
                      </td>
                      {role === "3" && (
                        <td>
                          <div
                            className="d-flex gap-2"
                            style={{ justifyContent: "center" }}
                          >
                            {/* <CopyToClipboard text={copiedTemplateData}>
                              <button
                                className="btn btn-secondary btn-sm"
                                onClick={() => getTemplateData(row._id)}
                              >
                                Copy
                              </button>
                            </CopyToClipboard> */}
                            <div>
                              <button
                                style={{ height: "30px", borderRadius: "4px" }}
                                className="btn btn-danger btn-sm"
                                onClick={() => deleteTemplate(row._id)}
                              >
                                Delete
                              </button>
                            </div>
                          </div>
                        </td>
                      )}
                    </>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* <div className="d-flex justify-content-end align-items-center p-3">
                    <button className="btn btn-light" disabled={currentPage === 1} onClick={() => setCurrentPage(currentPage - 1)}>
                        &lt; Prev
                    </button>
                    <span style={{ margin: "0 10px" }}>
                        Page {currentPage} of {totalPages}
                    </span>
                    <button className="btn btn-light" disabled={currentPage === totalPages} onClick={() => setCurrentPage(currentPage + 1)}>
                        Next &gt;
                    </button>

                    <select className="form-select mx-3" onChange={(e) => setCurrentPage(Number(e.target.value))}>
                        {Array.from({ length: totalPages }, (_, i) => i + 1).map((pageNo) => (
                            <option key={pageNo} value={pageNo} selected={currentPage === pageNo}>
                                {pageNo}
                            </option>
                        ))}
                    </select>
                </div> */}
        <div
          className="d-flex justify-content-end align-items-center"
          style={{ padding: "5px" }}
        >
          {preNextBtn("<<", 1)}
          <div style={{ marginRight: "10px" }}>
            page {currentPage} of {totalPages}
          </div>
          {preNextBtn(">>", totalPages)}
          <div>
            <select
              className="form-select"
              onChange={(e) => handlePageChange(e.target.value)}
            >
              <option disabled selected>
                go to page
              </option>
              {Array(totalPages)
                .fill()
                .map((_, index) => index + 1)
                .map((pageNo) => (
                  <option disabled={currentPage == pageNo} value={pageNo}>
                    {pageNo}
                  </option>
                ))}
            </select>
          </div>
        </div>
      </div>

      <Modal show={showPreview} onHide={handleClosePreview} centered size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Template Preview</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedTemplateId && (
            <WhatsAppMessagePreview id={selectedTemplateId} isViewMode />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClosePreview}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default TemplateApprovalStatus;
